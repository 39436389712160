// FIELDS OF TABLE ACCOUNT NUMBER
export default[
    {
        key: "account_user_name",
        visible: true
    },
    {
        key: "account_number",
        visible: true
    },
    {
        key: "status",
        thClass: 'text-center',
        tdClass: 'text-center',
        visible: true
    },
    {
        key: "created_by",
        visible: true
    },
    {
        key: "created_at",
        visible: true
    },
    {
        key: "actions",
        visible: true
    }
]