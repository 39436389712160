<template>
  <div>
    <b-modal
      size="lg"
      body-class="p-0"
      no-close-on-backdrop
      @hidden="$emit('hidden')"
      ref="modal-register-account-number"
      :title="title + ' ACCOUNT NUMBER'"
    >
      <div class="m-2">
        <ValidationObserver ref="observer">
          <div class="form-row">
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="accountNumber"
              >
                <b-form-group label="ACCOUNT NUMBER">
                  <b-form-input
                    v-model="dataRegister.accountNumber"
                    v-mask="'############'"
                    :class="errors[0]"
                  >
                  </b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6">
              <b-form-group label="USER">
                <b-form-input
                  type="text"
                  disabled
                  v-model="dataRegister.userName"
                  class="form-control"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="form-group col-md-12">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Status"
              >
                <b-form-group :class="errors[0]" label="STATUS">
                  <v-select
                    v-model="dataRegister.statusAccount"
                    :options="status"
                    :reduce="(item) => item.id"
                    :disabled="isUpdate"
                    
                  >
                  </v-select>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <template #modal-footer>
        <div>
          <b-button @click="$emit('hidden')">CANCEL</b-button>
        </div>
        <div>
          <b-button variant="primary" @click="setAction()"
            ><feather-icon icon="SaveIcon" class="mr-1" />
            {{ textButton }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import AccountNumberService from "@/views/correspondence/views/settings/service/settings.service";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import VueMask from "v-mask";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "is-invalid",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    VueMask,
  },

  props: {
    dataAccount: {
      type: Object,
      required: false,
    },
    isUpdate: {
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modal-register-account-number");
  },
  created() {
    if (this.isUpdate) {
      if (this.dataAccount.idAccountNumber) {
        this.showAccountNumer();
        this.title = "UPDATE";
        this.textButton = "UPDATE";
      }
    }
    this.dataRegister.userName = this.currentUser.fullName;
  },
  data() {
    return {
      status: [
        { id: 1, label: "Active" },
        { id: 2, label: "Inactive" },
      ],
      mask: [],
      title: "ADD",
      textButton: "SAVE",

      dataRegister: {
        accountNumber: "",
        statusAccount: "",
        userName: "",
        user_id: "",
        id: "",
      },
    };
  },
  methods: {
    setAction() {
      if (this.isUpdate) {
        this.updateAccountNumber();
      } else {
        this.addAccountNumber();
      }
    },
    async addAccountNumber() {
      const result = await this.$refs.observer.validate();
      if (result) {
        try {
          const params = {
            accountNumber: this.dataRegister.accountNumber,
            userName: this.currentUser.fullName,
            user_id: this.currentUser.user_id,
            statusAccount: this.dataRegister.statusAccount,
          };

          const resultState = await this.showConfirmSwal();
          if (resultState.isConfirmed) {
            this.addPreloader();
            const data = await AccountNumberService.addAccountNumber(params);
            if (data.status == 200) {
              this.showSuccessSwal("New origin state registered successfully!");
              this.$emit("closeModalRegister");
              this.$emit("refreshTable");
            }
          }
        } catch (error) {
            this.showInfoSwal("THE USER ALREADY HAS AN ACTIVE ACCOUNT OR AN ACCOUNT NUMBER ALREADY EXISTS WITH THE SAME DATA ENTERED");
        } finally {
          this.removePreloader();
        }
      }
    },
    showAccountNumer() {
      this.dataRegister.accountNumber = this.dataAccount.accountNumber;
      this.dataRegister.userName = this.dataAccount.userName;
      this.dataRegister.id = this.dataAccount.idAccountNumber;
      this.dataRegister.statusAccount = this.dataAccount.status;
    },
    async updateAccountNumber() {
      const result = await this.$refs.observer.validate();
      if (result) {
        try {
          const params = {
            accountNumber: this.dataRegister.accountNumber,
            userName: this.currentUser.fullName,
            user_id: this.currentUser.user_id,
            idAccountNumber: this.dataAccount.idAccountNumber,
            statusAccount: this.dataRegister.statusAccount,
          };
          const resultState = await this.showConfirmSwal();
          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await AccountNumberService.updateAccountNumber(params);
            if (resul.status == 200) {
              this.showSuccessSwal("New origin state registered successfully!");
              this.$emit("closeModalRegister");
              this.$emit("refreshTable");
            }
          }
        } catch (error) {
            this.showInfoSwal("THE USER ALREADY HAS AN ACTIVE ACCOUNT OR AN ACCOUNT NUMBER ALREADY EXISTS WITH THE SAME DATA ENTERED");
        }finally {
          this.removePreloader();
        }
      }
    },
  },
};
</script>