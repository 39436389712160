import {amgApi} from '@/service/axios'

class CorrespondenceSettingsService{
    
    async getOriginStatesPaginated(params){
        try {
            const data = await amgApi.post('/correspondence/get-origin-states-paginated', params)
            return data
        } catch (error) {
            throw error
        }
    }

    async addNewOriginState(params){
        try {
            const data = await amgApi.post('/correspondence/add-new-origin-state', params)
            return data
        } catch (error) {
            console.log("Something went wrong on addNewOriginState")
            throw error
        }
    }

    async changeStatusOriginState(params){
        try {
            const status = await amgApi.post('/correspondence/change-status-origin-state',params)
            return status
        } catch (error) {
            console.log('Something went wrong on changeStatusOriginState')
            throw error
        }
    }

    async deletionLogicalOriginState(params){
        try {
            const status = await amgApi.post('/correspondence/deletion-logical-origin-state',params)
            return status
        } catch (error) {
            console.log('Something went wrong on deletionLogicalOriginState')
            throw error
        }
    }

    async addAccountNumber(params){
        try {
            const status = await amgApi.post('/correspondence/add-account-number',params)
            return status
        } catch (error) {
            console.log('Something went wrong on deletionLogicalOriginState')
        }
    }

    async updateAccountNumber(params){
        try {
            const status = await amgApi.post('/correspondence/update-account-number',params)
            return status
        } catch (error) {
            console.log('Something went wrong on deletionLogicalOriginState')
        }
    }

    async getCorrespondenceAccountsPaginated(params){
        try{
            const data = await amgApi.post('/correspondence/get-correspondence-accounts-paginated',params)
            return data
        }catch(error){
            console.log('Something went wrong on getCorrespondenceAccountsPaginated')
            throw error
        }
    }

    async deletionLogicalCorrespondenceAccounts(params){
        try {
            const status = await amgApi.post('/correspondence/deletion-logical-correspondence-account-number',params)
            return status
        } catch (error) {
            console.log('Something went wrong on deletionLogicalCorrespondenceAccounts')
            throw error
        }
    }

    async changeStatusCorrespondenceAccount(params){
        try {
            const status = await amgApi.post('/correspondence/change-status-correspondence-account-number', params)
            return status
        } catch (error) {
            console.log('Something went wrong on changeStatusCorrespondenceAccount')
            throw error
        }
    }

}

export default new CorrespondenceSettingsService()