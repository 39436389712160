<template>
  <div>
    <div>
      <div>
            <h2>LIST OF CORRESPONDENCE ACCOUNTS</h2>
                  <b-button class="float-right" variant="primary" @click="showModal">
        <feather-icon icon="PlusIcon" size="15" />
        <span> ADD </span>
      </b-button>
      </div>

    </div>
    <br><br>
        <hr />
    <filter-slot
      :no-visible-principal-filter="true"
      :filter-principal="{}"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="paginate.startPage"
      :to-page="paginate.toPage"
      :filter="[]"
      @reload="$refs['CorrespondenceAccountsTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="CorrespondenceAccountsTable"
          :items="myProvider"
          :fields="fields"
          :per-page="paginate.currentPage"
          :current-page="paginate.currentPage"
          no-border-collapse
          show-empty
          sticky-header="50vh"
          class="position-relative"
          table-class="text-nowrap"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge
              pill
              :variant="data.item.status_code == 1 ? 'primary' : 'danger'"
              class="w-100"
            >
              <span>
                {{ data.item.status }}
              </span>
            </b-badge>
          </template>

          <template #cell(created_at)="data">
            <span>
              {{ data.item.created_at | myGlobalDay }}
            </span>
          </template>

          <template #cell(actions)="data">
            <feather-icon
              :icon="
                data.item.status_code == 1 ? 'ThumbsDownIcon' : 'ThumbsUpIcon'
              "
              v-b-tooltip.hover.up="
                data.item.status_code == 1
                  ? 'Deactivate account'
                  : 'Activate account'
              "
              :class="
                data.item.status_code == 1 ? 'text-danger' : 'text-success'
              "
              class="cursor-pointer mr-1"
              size="15"
              @click="
                changeStatusCorrespondenceAccount(
                  data.item.id,
                  data.item.status_code == 1 ? 2 : 1
                )
              "
            />
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer text-warning mr-1"
              size="15"
              @click="showAccountNumber(data.item)"
              v-b-tooltip.hover.top="'Edit correspondence account'"
            />
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer text-danger"
              size="15"
              v-b-tooltip.hover.top="'Delete correspondence account'"
              @click="deletionLogical(data.item.id)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <ModalAccountNumber
      v-if="statusModalRegisterAccountNumber"
      @hidden="closeModal"
      :dataAccount="isUpdate ? dataUpdate : {}"
      :isUpdate="isUpdate"
      @refreshTable="$refs['CorrespondenceAccountsTable'].refresh()"
      @closeModalRegister="statusModalRegisterAccountNumber = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AccountNumberFields from "@/views/correspondence/views/settings/data/account-number.fields";
import AccountNumberService from "@/views/correspondence/views/settings/service/settings.service";
import ModalAccountNumber from "@/views/correspondence/views/settings/components/ModalAccountNumber.vue";

export default {
  components: { ModalAccountNumber },
  data() {
    return {
      isUpdate: false,
      dataUpdate: {
        status:"",
        accountNumber: "",
        userName: "",
        idAccountNumber: "",
      },
      statusModalRegisterAccountNumber: false,
      fields: AccountNumberFields,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
        startPage: 0,
        toPage: 0,
      },
      isBusy: false,
    };
  },
  methods: {
    async myProvider() {
      try {
        const params = {
          perpage: this.paginate.perPage,
          npage: this.paginate.currentPage,
        };
        const { data, status } =
          await AccountNumberService.getCorrespondenceAccountsPaginated(params);
        if (status == 200) {
          this.totalRows = data.total;
          if (data.from) {
            this.paginate.startPage = data.from;
          } else {
            this.paginate.startPage = 0;
          }

          if (data.to) {
            this.paginate.toPage = data.to;
          } else {
            this.paginate.toPage = 0;
          }
          return data.data || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    showAccountNumber(params) {
      this.isUpdate = true;
      this.statusModalRegisterAccountNumber = true;
      this.dataUpdate.accountNumber = params.account_number;
      this.dataUpdate.userName = params.account_user_name;
      this.dataUpdate.status = params.status_code;
      this.dataUpdate.idAccountNumber = params.id;
    },
    async deletionLogical(account_number_id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const params = {
            account_number_id: account_number_id,
            user_id: this.currentUser.user_id,
          };
          const { status } =
            await AccountNumberService.deletionLogicalCorrespondenceAccounts(
              params
            );
          if (status == 200) {
            this.showSuccessSwal("Account number deleted successfully");
            this.$refs["CorrespondenceAccountsTable"].refresh();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async changeStatusCorrespondenceAccount(account_number_id, status_id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const params = {
            account_number_id: account_number_id,
            status_id: status_id,
          };
          const { data, status } =
            await AccountNumberService.changeStatusCorrespondenceAccount(
              params
            );
          if(data[0]){
            this.showInfoSwal(data[0].message)
          }else{
            if(status == 200) {
              this.showSuccessSwal("Status changed successfully");
              this.$refs["CorrespondenceAccountsTable"].refresh();
            }
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.isUpdate = false;
      this.statusModalRegisterAccountNumber = false;
    },
    showModal() {
      this.statusModalRegisterAccountNumber = true;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>