var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',[_c('h2',[_vm._v("LIST OF CORRESPONDENCE ACCOUNTS")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.showModal}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"15"}}),_c('span',[_vm._v(" ADD ")])],1)],1)]),_c('br'),_c('br'),_c('hr'),_c('filter-slot',{attrs:{"no-visible-principal-filter":true,"filter-principal":{},"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.paginate.startPage,"to-page":_vm.paginate.toPage,"filter":[]},on:{"reload":function($event){return _vm.$refs['CorrespondenceAccountsTable'].refresh()}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"CorrespondenceAccountsTable",staticClass:"position-relative",attrs:{"items":_vm.myProvider,"fields":_vm.fields,"per-page":_vm.paginate.currentPage,"current-page":_vm.paginate.currentPage,"no-border-collapse":"","show-empty":"","sticky-header":"50vh","table-class":"text-nowrap","busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(status)",fn:function(data){return [_c('b-badge',{staticClass:"w-100",attrs:{"pill":"","variant":data.item.status_code == 1 ? 'primary' : 'danger'}},[_c('span',[_vm._v(" "+_vm._s(data.item.status)+" ")])])]}},{key:"cell(created_at)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.created_at))+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.up",value:(
              data.item.status_code == 1
                ? 'Deactivate account'
                : 'Activate account'
            ),expression:"\n              data.item.status_code == 1\n                ? 'Deactivate account'\n                : 'Activate account'\n            ",modifiers:{"hover":true,"up":true}}],staticClass:"cursor-pointer mr-1",class:data.item.status_code == 1 ? 'text-danger' : 'text-success',attrs:{"icon":data.item.status_code == 1 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',"size":"15"},on:{"click":function($event){return _vm.changeStatusCorrespondenceAccount(
                data.item.id,
                data.item.status_code == 1 ? 2 : 1
              )}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Edit correspondence account'),expression:"'Edit correspondence account'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer text-warning mr-1",attrs:{"icon":"EditIcon","size":"15"},on:{"click":function($event){return _vm.showAccountNumber(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Delete correspondence account'),expression:"'Delete correspondence account'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer text-danger",attrs:{"icon":"TrashIcon","size":"15"},on:{"click":function($event){return _vm.deletionLogical(data.item.id)}}})]}}])})]},proxy:true}])}),(_vm.statusModalRegisterAccountNumber)?_c('ModalAccountNumber',{attrs:{"dataAccount":_vm.isUpdate ? _vm.dataUpdate : {},"isUpdate":_vm.isUpdate},on:{"hidden":_vm.closeModal,"refreshTable":function($event){return _vm.$refs['CorrespondenceAccountsTable'].refresh()},"closeModalRegister":function($event){_vm.statusModalRegisterAccountNumber = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }